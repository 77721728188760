import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../constants/api';

const RecapPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const QuizRecap = ({ recap, onTopicSelect }) => {
  const navigate = useNavigate();
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('userLanguage') || 'en';
    setLanguage(storedLanguage);
    fetchTranslations(storedLanguage);
  }, []);

  const fetchTranslations = async (lang) => {
    const keys = [
      'Performance Summary',
      'Mastered Concepts',
      'Improvement Areas',
      'Suggested Topics',
      'Knowledge Graph Explanationionships',
      'Start New Session'
    ];

    try {
      const response = await fetch(API_ENDPOINTS.TRANSLATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          texts: keys,
          targetLanguage: lang
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setTranslations(result);
    } catch (error) {
      console.error('Error fetching translations:', error);
      setTranslations({});
    }
  };

  const t = (key) => translations[key] || key;

  return (
    <RecapPaper elevation={3}>
      <Typography variant="h5" gutterBottom>{t('Performance Summary')}</Typography>
      <Typography variant="body1" paragraph>{recap.performanceSummary}</Typography>
      
      <SectionTitle variant="h6">{t('Mastered Concepts')}:</SectionTitle>
      <List>
        {recap.masteredConcepts.map((concept, index) => (
          <StyledListItem key={index}>
            <Typography variant="body1">• {concept}</Typography>
          </StyledListItem>
        ))}
      </List>
      
      <SectionTitle variant="h6">{t('Improvement Areas')}:</SectionTitle>
      <List>
        {recap.improvementAreas.map((area, index) => (
          <StyledListItem key={index}>
            <Typography variant="body1">• {area}</Typography>
          </StyledListItem>
        ))}
      </List>
      
      <SectionTitle variant="h6">{t('Suggested Topics')}:</SectionTitle>
      <List>
        {recap.suggestedTopics.map((topic, index) => (
          <StyledListItem key={index}>
            <Button onClick={() => onTopicSelect(topic)}>• {topic}</Button>
          </StyledListItem>
        ))}
      </List>
      
      <SectionTitle variant="h6">{t('Knowledge Graph Explanationionships')}:</SectionTitle>
      <Typography variant="body1" paragraph>{recap.knowledgeGraphExplanation}</Typography>

      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/learning')}
        >
          {t('Start New Session')}
        </Button>
      </Box>
    </RecapPaper>
  );
};

export default QuizRecap;