import * as Sentry from "@sentry/react";
import { Routes } from "react-router-dom";

export const initSentry = () => {
  Sentry.init({
    dsn: "https://9d5e016ff6785ed1e2164e19043c4faa@o4505737510125568.ingest.us.sentry.io/4507816277966848",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    tracePropagationTargets: ["https://www.jobebe.com"],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);