import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Learning from "./pages/Learning";
import Login from "./pages/Login";
import Speaking from "./components/Speaking";
import Writing from "./components/Writing";
import { AuthProvider } from "./contexts/AuthContext";
import Language from "./components/Language";
import Profile from "./components/Profile";
import Pathway from "./components/Pathway";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/learning" element={<Learning />} />
          <Route path="/speaking" element={<Speaking />} />
          <Route path="/writing" element={<Writing />} />
          <Route path="/language" element={<Language />} />
          <Route path="/profile" element={<Profile />} />
          <Route path='/pathway' element={<Pathway />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;