import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { app } from "../services/providers/firebase";
import TopBar from '../components/TopBar';
import Sidebar from '../components/Sidebar';
import { Button, TextField, Typography, Divider, Container } from '@mui/material';
import GoogleIcon from '../components/Login/GoogleIcon';
import MicrosoftIcon from '../components/Login/MicrosoftIcon';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const ResponsiveContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth: '400px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

export function useLogout() {
  const auth = getAuth(app);
  return async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  };
}

export default function Login() {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        navigate('/learning');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleAuth = async (e) => {
    e.preventDefault();
    setError('');
    const auth = getAuth(app);

    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate('/language');
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/learning');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSSOSignIn = async (provider) => {
    const auth = getAuth(app);
    try {
      const result = await signInWithPopup(auth, provider);
      // Check if the user is new
      if (result.user && result._tokenResponse.isNewUser) {
        navigate('/language');
      } else {
        navigate('/learning');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = () => handleSSOSignIn(new GoogleAuthProvider());

  const handleMicrosoftSignIn = () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({ prompt: 'select_account' });
    handleSSOSignIn(provider);
  };

  const handleSignOut = async () => {
    const auth = getAuth(app);
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      setError('Error signing out. Please try again.');
    }
  };

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} user={user} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} user={user} />
      <Main open={open}>
        <DrawerHeader />
        <ResponsiveContainer>
          {!user ? (
            <Box component="form" onSubmit={handleAuth} sx={{ width: '100%' }}>
              <Typography variant="h5" align="center" sx={{ mb: 3 }}>
                {isSignUp ? 'Sign Up' : 'Sign In'}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && <Typography color="error" align="center">{error}</Typography>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {isSignUp ? 'Sign Up' : 'Sign In'}
              </Button>
              <Button
                fullWidth
                onClick={() => setIsSignUp(!isSignUp)}
              >
                {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
              </Button>
              <Divider sx={{ my: 2 }}>OR</Divider>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleSignIn}
                sx={{ mt: 1, mb: 2 }}
              >
                Sign in with Google
              </Button>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<MicrosoftIcon />}
                onClick={handleMicrosoftSignIn}
                sx={{ mt: 1, mb: 2 }}
              >
                Sign in with Microsoft
              </Button>
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Welcome, {user.email}!</Typography>
              <Button
                variant="contained"
                onClick={handleSignOut}
                sx={{ mt: 2 }}
              >
                Sign Out
              </Button>
            </Box>
          )}
        </ResponsiveContainer>
      </Main>
    </Box>
  );
}