import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Paper,Button, CircularProgress, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import TopBar from '../components/TopBar';
import Sidebar from '../components/Sidebar';
import ScienceChat from '../components/Learning/ScienceChat';
import QuizRecap from '../components/Learning/QuizRecap';
import RelatedVideos from '../components/Learning/RelatedVideos';
import { useAuth } from '../contexts/AuthContext';
import { getFunctions, httpsCallable } from "firebase/functions";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 'calc(100vh - 64px)', // Subtract AppBar height
  marginTop: 64, // AppBar height
}));

const LeftSection = styled(Box)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}));

const QuizSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ChatSection = styled(Paper)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 140px)', // Adjust this value as needed
  maxHeight: 'calc(100vh - 140px)', // Ensure it doesn't exceed the viewport height
}));

const ChatContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse', // Change this line
  scrollBehavior: 'smooth', // Add this line
}));

const LoadingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: '200px',
}));

function Learning() {
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [quizHistory, setQuizHistory] = useState([]);
  const [score, setScore] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [questionCount, setQuestionCount] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [topic, setTopic] = useState('');
  const [videos, setVideos] = useState([]);
  const [language, setLanguage] = useState(null); // Default to English
  const [quizRecap, setQuizRecap] = useState(null);

  useEffect(() => {
    const storedTopic = localStorage.getItem('selectedTopic');
    const initialTopic = storedTopic || location.state?.initialMessage || 'General Science';
    setTopic(initialTopic);
    // Get the language from localStorage or use a default value
    const storedLanguage = localStorage.getItem('userLanguage') || 'en';
    setLanguage(storedLanguage);
  }, [location]);

  useEffect(() => {
    if (user && topic) {
      fetchNextQuestion();
      fetchYoutubeVideos();
    }
  }, [user, topic, language]);

  const fetchYoutubeVideos = useCallback(async () => {
    if (!topic || !language) return;

    setIsVideoLoading(true);
    try {
      const functions = getFunctions();
      const searchYoutubeFunction = httpsCallable(functions, 'searchYoutube');
      const result = await searchYoutubeFunction({ 
        query: topic,
        language: language
      });
      setVideos(result.data.videos);
    } catch (error) {
      console.error('Error fetching YouTube videos:', error);
    } finally {
      setIsVideoLoading(false);
    }
  }, [topic, language]);

  const fetchNextQuestion = useCallback(async () => {
    if (!user || !topic) return;

    setIsLoading(true);
    try {
      const functions = getFunctions();
      const checkQuizAnswerFunction = httpsCallable(functions, 'checkQuizAnswer');
      const result = await checkQuizAnswerFunction({ 
        action: 'nextQuestion', 
        quizHistory,
        userId: user.uid,
        topicId: topic,
        language: language
      });
      setCurrentQuestion(result.data);
    } catch (error) {
      console.error('Error fetching next question:', error);
    } finally {
      setIsLoading(false);
    }
  }, [quizHistory, user, topic, language]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitAnswer = async (event) => {
    event.preventDefault();
    if (!selectedOption || !currentQuestion) return;

    setIsLoading(true);
    try {
      const functions = getFunctions();
      const checkQuizAnswerFunction = httpsCallable(functions, 'checkQuizAnswer');
      const result = await checkQuizAnswerFunction({
        action: 'checkAnswer',
        userId: user.uid,
        topicId: topic,
        currentQuestion: currentQuestion.question,
        userAnswer: selectedOption,
        options: currentQuestion.options,
        language: language
      });
      
      const response = result.data;
      
      const updatedQuizHistory = [...quizHistory, { 
        question: currentQuestion, 
        userAnswer: selectedOption, 
        correct: response.isCorrect 
      }];
      setQuizHistory(updatedQuizHistory);
      
      if (response.isCorrect) {
        setScore(score + 1);
      }

      setSelectedOption('');
      setQuestionCount(questionCount + 1);

      if (questionCount + 1 >= 5) {
        setQuizCompleted(true);
        await recapLearningSession(updatedQuizHistory);
      } else {
        setCurrentQuestion(response);
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const recapLearningSession = async (finalQuizHistory) => {
    try {
      const functions = getFunctions();
      const checkQuizAnswerFunction = httpsCallable(functions, 'checkQuizAnswer');
      const result = await checkQuizAnswerFunction({
        action: 'recapLearningSession',
        userId: user.uid,
        topicId: topic,
        quizHistory: finalQuizHistory,
        score: finalQuizHistory.filter(item => item.correct).length,
        language: language
      });
      setQuizRecap(result.data);
    } catch (error) {
      console.error('Error recapping learning session:', error);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleVideoClick = (videoId) => {
    window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
  };

  const prepareChatData = useCallback(() => {
    return {
      action: 'chat',
      userId: user?.uid || 'anonymous',
      topicId: topic,
      language: language
    };
  }, [user, topic, language]);

  const handleTopicSelect = (newTopic) => {
    // Update the topic in localStorage
    localStorage.setItem('selectedTopic', newTopic);
    
    // Update the topic state
    setTopic(newTopic);
    
    // Reset the quiz state
    setQuizHistory([]);
    setScore(0);
    setQuestionCount(0);
    setQuizCompleted(false);
    setQuizRecap(null);
    
    // Fetch the first question for the new topic
    fetchNextQuestion();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} user={user} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} user={user} />
      <Main open={open}>
        <ContentWrapper>
          <LeftSection>
            <RelatedVideos 
              isLoading={isVideoLoading}
              videos={videos}
              handleVideoClick={handleVideoClick}
            />
            <QuizSection>
              <Typography variant="h6" gutterBottom>Adaptive Quiz</Typography>
              {isLoading ? (
                <LoadingWrapper>
                  <CircularProgress />
                </LoadingWrapper>
              ) : !quizCompleted ? (
                <form onSubmit={handleSubmitAnswer}>
                  <Typography variant="subtitle1" gutterBottom>Question {questionCount + 1} of 5</Typography>
                  {currentQuestion && (
                    <>
                      <Typography variant="body1" gutterBottom>{currentQuestion.question}</Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="quiz"
                          name="quiz"
                          value={selectedOption}
                          onChange={handleOptionChange}
                        >
                          {currentQuestion.options && currentQuestion.options.map((option, index) => (
                            <FormControlLabel
                              key={index}
                              value={option}
                              control={<Radio />}
                              label={option}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!selectedOption || isLoading}
                          startIcon={isLoading ? <CircularProgress size={24} /> : null}
                          fullWidth
                        >
                          Submit Answer
                        </Button>
                      </Box>
                    </>
                  )}
                </form>
              ) : (
                quizRecap && <QuizRecap recap={quizRecap} onTopicSelect={handleTopicSelect} />
              )}
            </QuizSection>
          </LeftSection>
          <ChatSection>
            <Typography variant="h6" gutterBottom>Science AI Supporter</Typography>
            <ChatContent>
              <ScienceChat 
                chatData={prepareChatData()}
              />
            </ChatContent>
          </ChatSection>
        </ContentWrapper>
      </Main>
    </Box>
  );
}

export default Learning;