// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyCOqc7QBqcMe3CemnErywidSKo1MKtRzZg",
  authDomain: "www.jobebe.com",
  projectId: "journey-to-become-better",
  storageBucket: "journey-to-become-better.appspot.com",
  messagingSenderId: "106331066820",
  appId: "1:106331066820:web:55a513b5bf0c2e0233d491",
  measurementId: "G-Q22KZMRXCY"  
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lfn4y4qAAAAAE0oGHFMqgP9sc9tcJXoX7d1Re1D'),
  isTokenAutoRefreshEnabled: true
});
