import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, CircularProgress, Card, CardMedia, CardContent, IconButton, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const VideoSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const VideoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  padding: theme.spacing(1, 0),
}));

const VideoCard = styled(Card)(({ theme }) => ({
  width: 280,
  marginRight: theme.spacing(2),
  display: 'inline-block',
  verticalAlign: 'top',
  position: 'relative',
  cursor: 'pointer',
  '&:hover .playIcon': {
    opacity: 1,
  },
}));

const PlayIconOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '140px', // Match the height of CardMedia
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.3)',
  opacity: 0,
  transition: 'opacity 0.3s',
}));

const LoadingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: '200px',
}));

const VideoTitle = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '1.2em',
  height: '2.4em', // 2 lines * 1.2em line-height
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

function RelatedVideos({ isLoading, videos, handleVideoClick }) {
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = 3;

  const pageCount = Math.ceil(videos.length / videosPerPage);
  const displayedVideos = videos.slice(currentPage * videosPerPage, (currentPage + 1) * videosPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(pageCount - 1, prev + 1));
  };

  return (
    <VideoSection>
      <Typography variant="h6" gutterBottom>Related Videos</Typography>
      {isLoading ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <>
          <VideoContainer>
            {displayedVideos.map((video) => (
              <VideoCard 
                key={video.videoId} 
                onClick={() => handleVideoClick(video.videoId)}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={video.thumbnailUrl}
                  alt={video.title}
                />
                <PlayIconOverlay className="playIcon">
                  <IconButton 
                    aria-label="play"
                    sx={{ color: 'white' }}
                  >
                    <PlayArrowIcon fontSize="large" />
                  </IconButton>
                </PlayIconOverlay>
                <CardContent>
                  <VideoTitle variant="subtitle1">
                    {video.title}
                  </VideoTitle>
                </CardContent>
              </VideoCard>
            ))}
          </VideoContainer>
          <NavigationContainer>
            <Button 
              onClick={handlePrevPage} 
              disabled={currentPage === 0}
              startIcon={<ArrowBackIosNewIcon />}
            >
              Previous
            </Button>
            <Typography variant="body2" sx={{ mx: 2 }}>
              {`${currentPage + 1} / ${pageCount}`}
            </Typography>
            <Button 
              onClick={handleNextPage} 
              disabled={currentPage === pageCount - 1}
              endIcon={<ArrowForwardIosIcon />}
            >
              Next
            </Button>
          </NavigationContainer>
        </>
      )}
    </VideoSection>
  );
}

export default RelatedVideos;